$grey-1: #F6F6F6;
$grey-2: #8E8E8E;
$grey-3: #2D2D2D;

$blue-1: #00597A;
$blue-2: #0082A2;
$blue-3: #709EB0;
$blue-4: #DCE3EF;

$purple-1: #AD5D93;
$purple-2: #D7B0CA;
$purple-3: #D3C0C8;
