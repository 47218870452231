@import './fonts';
@import './colours';

html, body {
  box-sizing: border-box;
  margin: 0;
  font-family: $font-standard;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  background-color: $grey-1;
  color: $grey-3;
  scroll-behavior: smooth;
  font-size: 1rem;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

img {
  /* For responsive images that adjust & adapt */
  height: auto;
  max-width: 100%;
  
  /* This isn't needed, but as a user, I get frustrated when I highlight text and the image gets highlighted too */
  user-select: none;
}

a, button {
  touch-action: manipulation; /* Element doesn't want double-tap on mobile to zoom */
}

svg {
  /* Prevent the SVG from altering cursor interaction */
  pointer-events: none;
}

iframe, video {
  /* Make iframes & videos fit the parent container by default */
  height: 100%;
  width: 100%
}

/* css reset*/
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
ol, ul {
	list-style: none;
  margin: 0;
  padding: 0;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}